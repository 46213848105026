import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"kitchen-list"}},[_c('kitchen-list-add-new',{on:{"refetch-data":_vm.fetchkitchens},model:{value:(_vm.isAddNewkitchenSidebarActive),callback:function ($$v) {_vm.isAddNewkitchenSidebarActive=$$v},expression:"isAddNewkitchenSidebarActive"}}),_c(VCard,[_c(VCardTitle,[_vm._v(" Search & Filter ")]),_c(VRow,{staticClass:"px-2 ma-0"},[_c(VCol,{attrs:{"cols":"12","sm":"4"}})],1),_c(VCardText,{staticClass:"d-flex align-center flex-wrap pb-0"},[_c(VTextField,{staticClass:"kitchen-search me-3 mb-4",attrs:{"placeholder":"Search","outlined":"","hide-details":"","dense":""},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}}),_c(VSpacer),_c('div',{staticClass:"d-flex align-center flex-wrap"},[_c(VBtn,{staticClass:"mb-4 me-3",attrs:{"color":"primary"},on:{"click":function($event){$event.stopPropagation();_vm.isAddNewkitchenSidebarActive = !_vm.isAddNewkitchenSidebarActive}}},[_c(VIcon,[_vm._v(_vm._s(_vm.icons.mdiPlus))]),_c('span',[_vm._v("Add New kitchen")])],1),_c(VBtn,{staticClass:"mb-4",attrs:{"color":"secondary","outlined":""},on:{"click":_vm.downLoadxlsx}},[_c(VIcon,{staticClass:"me-1",attrs:{"size":"17"}},[_vm._v(" "+_vm._s(_vm.icons.mdiExportVariant)+" ")]),_c('span',[_vm._v("Export")])],1)],1)],1),_c(VDataTable,{attrs:{"id":"table","headers":_vm.tableColumns,"items":_vm.kitchenListTable,"options":_vm.options,"server-items-length":_vm.totalkitchenListTable,"loading":_vm.loading},on:{"update:options":function($event){_vm.options=$event},"refetch-data":_vm.fetchkitchens},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"d-flex flex-column ms-3"},[_c('router-link',{staticClass:"text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none",attrs:{"to":{ name: 'apps-kitchens-view', params: { id: item.id } }}},[_vm._v(" "+_vm._s(item.name)+" ")])],1)])]}},{key:"item.role",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c(VAvatar,{class:("v-avatar-light-bg " + (_vm.resolvekitchenRoleVariant(item.role)) + "--text me-3"),attrs:{"size":"30","color":_vm.resolvekitchenRoleVariant(item.role)}},[_c(VIcon,{attrs:{"size":"18","color":_vm.resolvekitchenRoleVariant(item.role)}},[_vm._v(" "+_vm._s(_vm.resolvekitchenRoleIcon(item.role))+" ")])],1),_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(item.role))])],1)]}},{key:"item.rate",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-capitalize font-weight-semibold text--primary"},[_vm._v("5")])]}},{key:"item.currentPlan",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-capitalize font-weight-semibold text--primary"},[_vm._v(_vm._s(item.currentPlan))])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c(VChip,{staticClass:"v-chip-light-bg font-weight-semibold text-capitalize",class:((_vm.resolvekitchenStatusVariant(item.status)) + "--text"),attrs:{"small":"","color":_vm.resolvekitchenStatusVariant(item.status)}},[_vm._v(" "+_vm._s(item.status)+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c(VMenu,{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v(_vm._s(_vm.icons.mdiDotsVertical))])],1)]}}],null,true)},[_c(VList,[_c(VListItem,{attrs:{"to":{ name: 'apps-kitchens-view', params: { id: item.id } }}},[_c(VListItemTitle,[_c(VIcon,{staticClass:"me-2",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiFileDocumentOutline)+" ")]),_c('span',[_vm._v("Details")])],1)],1)],1)],1)]}}],null,true),model:{value:(_vm.selectedRows),callback:function ($$v) {_vm.selectedRows=$$v},expression:"selectedRows"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }