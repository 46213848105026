<template>
  <v-navigation-drawer
    :value="isAddNewkitchenSidebarActive"
    :permanent="isAddNewkitchenSidebarActive"
    temporary
    touchless
    :right="!$vuetify.rtl"
    :width="$vuetify.breakpoint.smAndUp ? 550 : '100%'"
    app
    @input="val => $emit('update:is-add-new-kitchen-sidebar-active', val)"
  >
    <v-card height="100%">
      <div class="drawer-header d-flex align-center mb-4">
        <span class="font-weight-semibold text-base text--primary">Add New kitchen</span>
        <v-spacer></v-spacer>
        <v-btn icon small @click="$emit('update:is-add-new-kitchen-sidebar-active', false)">
          <v-icon size="22">
            {{ icons.mdiClose }}
          </v-icon>
        </v-btn>
      </div>

      <v-card-text>
        <v-form ref="form" v-model="valid" @submit.prevent="onSubmit">
          <v-text-field
            v-model="kitchenData.name"
            outlined
            dense
            :rules="[validators.required]"
            label="kitchen Name"
            placeholder="John Doe"
            hide-details="auto"
            class="mb-6"
          ></v-text-field>

          <vue-tel-input-vuetify
            @onInput="onInput_contact_number"
            v-model="kitchenData.mobile_number"
            defaultCountry="JO"
            :onlyCountries="['JO', 'SA']"
            @keypress="isNumber($event)"
            @paste.prevent
            :rules="[validators.validtelfun(kitchenData.mobile_number, validtel)]"
            :maxLen="18"
            label="Mobile Number"
            placeholder="Mobile Number"
            class="uppercase"
          ></vue-tel-input-vuetify>

          <vue-tel-input-vuetify
            @onInput="onInput_emergency_number"
            v-model="kitchenData.emergency_number"
            defaultCountry="JO"
            :onlyCountries="['JO', 'SA']"
            @keypress="isNumber($event)"
            @paste.prevent
            :rules="[validators.validtelfun(kitchenData.emergency_number, validtel2)]"
            :maxLen="18"
            label="Emergency Number"
            placeholder="Emergency Number"
            class="uppercase"
          ></vue-tel-input-vuetify>

          <v-row>
            <v-col cols="12">
              <v-select
                v-model="kitchenData.work_days"
                outlined
                :items="working_days"
                dense
                multiple
                :rules="[validators.required]"
                item-text="day"
                item-value="id"
                label="Working Days"
                placeholder="Working Days"
                hide-details="auto"
              ></v-select>
            </v-col>
            <v-col cols="6">
              <v-menu :close-on-content-click="false" transition="scale-transition" offset-y eager min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="kitchenData.start_work_time"
                    label="Start Work Time"
                    readonly
                    outlined
                    dense
                    hide-details="auto"
                    v-bind="attrs"
                    :rules="[validators.required, momentValue('start')]"
                    v-on="on"
                  ></v-text-field>
                </template>
                <vuetify-time-select
                  v-model="kitchenData.start_work_time"
                  outlined
                  dense
                  :style="resolveBackGround()"
                  format="ampm"
                  hide-details="auto"
                ></vuetify-time-select>
              </v-menu>
            </v-col>

            <v-col cols="6">
              <v-menu :close-on-content-click="false" transition="scale-transition" offset-y eager min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="kitchenData.end_work_time"
                    label="End Work Time"
                    readonly
                    outlined
                    dense
                    hide-details="auto"
                    v-bind="attrs"
                    :rules="[validators.required, momentValue('end')]"
                    v-on="on"
                  ></v-text-field>
                </template>
                <vuetify-time-select
                  v-model="kitchenData.end_work_time"
                  outlined
                  dense
                  :style="resolveBackGround()"
                  format="ampm"
                  hide-details="auto"
                ></vuetify-time-select>
              </v-menu>
            </v-col>
          </v-row>

          <h3 class="mt-6">Address:</h3>

          <v-row>
            <v-col cols="6">
              <v-text-field
                v-model="kitchenData.latitude"
                :rules="[validators.decimal_integer_Validator]"
                outlined
                dense
                @input="canEdit = false"
                hide-spin-buttons
                label="Latitude"
                placeholder="latitude"
                hide-details="auto"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="kitchenData.longitude"
                :rules="[validators.decimal_integer_Validator]"
                outlined
                dense
                @input="canEdit = false"
                hide-spin-buttons
                label="Longitude"
                placeholder="longitude"
                hide-details="auto"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row class="mb-6">
            <v-col cols="6">
              <v-text-field
                v-model="kitchenData.street_name"
                :rules="[validators.required]"
                outlined
                dense
                hide-spin-buttons
                label="Street Name"
                placeholder="Street Name"
                hide-details="auto"
              ></v-text-field>
            </v-col>

            <v-col cols="6">
              <v-text-field
                v-model="kitchenData.building_number"
                :rules="[validators.required]"
                outlined
                dense
                hide-spin-buttons
                label="Building Number"
                placeholder="Building Number"
                hide-details="auto"
              ></v-text-field>
            </v-col>
          </v-row>

          <TheMap
            :locations="{ latitude: kitchenData.latitude, longitude: kitchenData.longitude }"
            @resiveLatLng="resiveLatLng"
            :canEdit="canEdit"
            type="Add"
          ></TheMap>

          <v-text-field
            v-model="kitchenData.username"
            outlined
            dense
            :rules="[validators.textNOspace]"
            label="User Name"
            placeholder="User Name"
            hide-details="auto"
            class="mb-6"
          ></v-text-field>

          <v-card-text v-if="message.username" style="color: #ff3342">
            {{ message.username[0] }}
          </v-card-text>

          <v-text-field
            v-model="kitchenData.password"
            type="password"
            :rules="[validators.passwordValidator]"
            outlined
            autocomplete="new-password"
            dense
            label="Password"
            placeholder="Password"
            hide-details="auto"
            class="mb-6 uppercase"
          >
          </v-text-field>

          <v-file-input
            v-model="kitchenData.image"
            accept=".jpg, .jpeg, .png"
            placeholder="Choose an image"
            outlined
            type="file"
            @change="message = ''"
            :rules="
              message.image
                ? [message.image[0]]
                : [validators.required, fileAccept(['jpg', 'jpeg', 'png'], kitchenData.image)]
            "
            dense
            label="Upload Image"
            class="mb-1"
            prepend-icon=""
            hint="Required Image dimensions: width:100px, height:100px, max-file-size: 500kb"
          >
          </v-file-input>
          <span
            v-if="showHint"
            :style="message.image && 'color:red'"
            class="mb-6"
            style="display: block; font-size: 11px"
            >Required Image dimensions: width:100px, height:100px, max-file-size: 500kb</span
          >

          <v-btn color="primary" class="me-3" type="submit"> Add </v-btn>
          <v-btn color="secondary" outlined type="reset" @click="resetkitchenData"> Cancel </v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </v-navigation-drawer>
</template>

<script>
import store from '@/store'
import {
  integerValidator,
  decimal_integer_Validator,
  required,
  textNOspace,
  passwordValidator,
  validtelfun,
} from '@core/utils/validation'
import { mdiClipboardFileOutline, mdiClose } from '@mdi/js'
import { ref } from '@vue/composition-api'
import Swal from 'sweetalert2'
import TheMap from '../TheMap.vue'
import moment from 'moment'
import { isString } from '@vueuse/shared'

export default {
  components: { TheMap },
  data() {
    return {
      showHint: true,
    }
  },
  methods: {
    hideHint() {
      this.showHint = false
    },
  },
  model: {
    prop: 'isAddNewkitchenSidebarActive',
    event: 'update:is-add-new-kitchen-sidebar-active',
  },
  props: {
    isAddNewkitchenSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    const working_days = ref([
      { id: 1, day: 'Sun' },
      { id: 2, day: 'Mon' },
      { id: 3, day: 'Tue' },
      { id: 4, day: 'Wed' },
      { id: 5, day: 'Thu' },
      { id: 6, day: 'Sat' },
      { id: 7, day: 'Fri' },
    ])
    const blankkitchenData = {
      name: '',
      mobile_number: '',
      emergency_number: '',
      start_work_time: '',
      end_work_time: '',
      image: null,
      latitude: null,
      longitude: null,
      work_days: [],
      username: null,
      password: null,
    }
    const validtel = ref(false)
    const validtel2 = ref(false)
    const valid = ref(false)
    const form = ref(null)
    const canEdit = ref(true)
    const message = ref('')
    const isEditedMap = ref(false)

    const momentValue = (type) => {
      if (moment(new Date(`12-25-1995 ${kitchenData.value.end_work_time}`).toISOString()).isAfter(new Date(`12-25-1995 ${kitchenData.value.start_work_time}`).toISOString())) {
        return true
      } else if (type == 'start') {
        return 'Start time should before end time'
      }
      else if (type == 'end') {
        return 'End time should After start time'
      }
    }

    const resiveLatLng = mapCoordinates => {
      canEdit.value = true
      kitchenData.value.latitude = mapCoordinates.lat
      kitchenData.value.longitude = mapCoordinates.lng
      if (mapCoordinates.lat === '32.0000' && mapCoordinates.lng === '36.0000') {
        isEditedMap.value = false
      } else {
        isEditedMap.value = true
      }
    }
    const validate = () => {
      form.value.validate()
    }
    const fileAccept = (accept, data) => {
      if (data && !isString(data)) {
        if (data.size > 500000) {
          return `MAX SIZE 500KB`
        }
        if (accept.includes(data.name.split('.')[1])) {
          return true
        } else {
          return `Please choose file path (${accept})`
        }
      }
      return true
    }
    const resolveBackGround = () => {
      if (localStorage.getItem('materio-active-theme') == 'light') {
        return 'background-color: #fff;'
      }

      if (localStorage.getItem('materio-active-theme') == 'dark') {
        return 'background-color: #28243D;'
      }
    }
    const onInput_contact_number = ({ number, isValid }) => {
      if (number?.e164) {
        kitchenData.value.mobile_number = number.e164
        validtel.value = isValid
      } else {
        validtel.value = false
      }
      let tester = /^(\+\d{0,}\s?\d{0,}\s?\d{0,}\s?\d{0,})?$/.test(number.input)
      let tester2 = /^(\+\d{0,}\s?\d{0,}[-]?\d{0,}[-]?\d{0,})?$/.test(number.input)
      if (!tester && !tester2) {
        validtel.value = false
      }
    }

    const onInput_emergency_number = ({ number, isValid }) => {
      if (number?.e164) {
        kitchenData.value.emergency_number = number.e164
        validtel2.value = isValid
      } else {
        validtel2.value = false
      }
      let tester = /^(\+\d{0,}\s?\d{0,}\s?\d{0,}\s?\d{0,})?$/.test(number.input)
      let tester2 = /^(\+\d{0,}\s?\d{0,}[-]?\d{0,}[-]?\d{0,})?$/.test(number.input)
      if (!tester && !tester2) {
        validtel2.value = false
      }
    }
    const resetForm = () => {
      form.value.reset()
    }

    const kitchenData = ref(JSON.parse(JSON.stringify(blankkitchenData)))

    const resetkitchenData = () => {
      kitchenData.value = JSON.parse(JSON.stringify(blankkitchenData))
      resetForm()
      emit('update:is-add-new-kitchen-sidebar-active', false)
    }

    const onSubmit = () => {
      if (valid.value) {
        let end_work_time = moment(`Mon 03-Jul-2017, ${kitchenData.value.end_work_time}`).format('hh:mm A')
        kitchenData.value.end_work_time = end_work_time

        let start_work_time = moment(`Mon 03-Jul-2017, ${kitchenData.value.start_work_time}`).format('hh:mm A')
        kitchenData.value.start_work_time = start_work_time

        store
          .dispatch('app-kitchen/addkitchen', kitchenData.value)
          .then(response => {
            message.value = ''
            Swal.fire({
              position: 'center',
              icon: 'success',
              title: response.data.data.message,
              showConfirmButton: false,
              timer: 1500,
            })
            emit('refetch-data')
            resetkitchenData()
          })
          .catch(error => {
            console.log('error', error)
            if (error?.data?.message) {
              message.value = error?.data?.message
              console.log('message', message.value)
            }
          })
      } else {
        validate()
      }
    }

    return {
      momentValue,
      fileAccept,
      resolveBackGround,
      validtel2,
      validtel,
      onInput_emergency_number,
      onInput_contact_number,
      message,
      working_days,
      canEdit,
      isEditedMap,
      resiveLatLng,
      form,
      kitchenData,
      valid,
      resetkitchenData,
      onSubmit,
      validate,

      // validation
      validators: {
        integerValidator,
        required,
        decimal_integer_Validator,
        passwordValidator,
        validtelfun,
        textNOspace,
      },
      icons: {
        mdiClose,
        mdiClipboardFileOutline,
      },
    }
  },
}
</script>
<style scoped>
div >>> .caption {
  white-space: nowrap;
}
</style>
