<template>
  <div id="kitchen-list">
    <!-- app drawer -->
    <kitchen-list-add-new v-model="isAddNewkitchenSidebarActive" @refetch-data="fetchkitchens"></kitchen-list-add-new>

    <!-- list filters -->
    <v-card>
      <v-card-title> Search &amp; Filter </v-card-title>
      <v-row class="px-2 ma-0">
        <!-- status filter -->
        <v-col cols="12" sm="4"> </v-col>
      </v-row>

      <!-- actions -->
      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <!-- search -->
        <v-text-field
          v-model="searchQuery"
          placeholder="Search"
          outlined
          hide-details
          dense
          class="kitchen-search me-3 mb-4"
        >
        </v-text-field>

        <v-spacer></v-spacer>

        <div class="d-flex align-center flex-wrap">
          <v-btn
            color="primary"
            class="mb-4 me-3"
            @click.stop="isAddNewkitchenSidebarActive = !isAddNewkitchenSidebarActive"
          >
            <v-icon>{{ icons.mdiPlus }}</v-icon>
            <span>Add New kitchen</span>
          </v-btn>

          <v-btn color="secondary" @click="downLoadxlsx" outlined class="mb-4">
            <v-icon size="17" class="me-1">
              {{ icons.mdiExportVariant }}
            </v-icon>
            <span>Export</span>
          </v-btn>
        </div>
      </v-card-text>

      <!-- table -->
      <v-data-table
        id="table"
        v-model="selectedRows"
        :headers="tableColumns"
        :items="kitchenListTable"
        :options.sync="options"
        :server-items-length="totalkitchenListTable"
        :loading="loading"
        @refetch-data="fetchkitchens"
      >
        <!-- name -->
        <template #[`item.name`]="{ item }">
          <div class="d-flex align-center">
            <div class="d-flex flex-column ms-3">
              <router-link
                :to="{ name: 'apps-kitchens-view', params: { id: item.id } }"
                class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
              >
                {{ item.name }}
              </router-link>
            </div>
          </div>
        </template>

        <!-- role -->
        <template #[`item.role`]="{ item }">
          <div class="d-flex align-center">
            <v-avatar
              size="30"
              :color="resolvekitchenRoleVariant(item.role)"
              :class="`v-avatar-light-bg ${resolvekitchenRoleVariant(item.role)}--text me-3`"
            >
              <v-icon size="18" :color="resolvekitchenRoleVariant(item.role)">
                {{ resolvekitchenRoleIcon(item.role) }}
              </v-icon>
            </v-avatar>
            <span class="text-capitalize">{{ item.role }}</span>
          </div>
        </template>

        <!-- Rate -->
        <template #[`item.rate`]="{ item }">
          <span class="text-capitalize font-weight-semibold text--primary">5</span>
        </template>

        <!-- plan -->
        <template #[`item.currentPlan`]="{ item }">
          <span class="text-capitalize font-weight-semibold text--primary">{{ item.currentPlan }}</span>
        </template>

        <!-- status -->
        <template #[`item.status`]="{ item }">
          <v-chip
            small
            :color="resolvekitchenStatusVariant(item.status)"
            :class="`${resolvekitchenStatusVariant(item.status)}--text`"
            class="v-chip-light-bg font-weight-semibold text-capitalize"
          >
            {{ item.status }}
          </v-chip>
        </template>

        <!-- actions -->
        <template #[`item.actions`]="{ item }">
          <v-menu bottom left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item :to="{ name: 'apps-kitchens-view', params: { id: item.id } }">
                <v-list-item-title>
                  <v-icon size="20" class="me-2">
                    {{ icons.mdiFileDocumentOutline }}
                  </v-icon>
                  <span>Details</span>
                </v-list-item-title>
              </v-list-item>
<!-- 
              <v-list-item link @click="handleDelete(item.id)">
                <v-list-item-title>
                  <v-icon size="20" class="me-2">
                    {{ icons.mdiDeleteOutline }}
                  </v-icon>
                  <span>Delete</span>
                </v-list-item-title>
              </v-list-item> -->
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import store from '@/store'
import {
  mdiAccountOutline,
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiExportVariant,
  mdiFileDocumentOutline,
  mdiPlus,
  mdiSquareEditOutline,
} from '@mdi/js'
import { onUnmounted, ref } from '@vue/composition-api'
import Swal from 'sweetalert2'
import * as dotenv from 'dotenv'

// sidebar
import { avatarText } from '@core/utils/filter'
import kitchenStoreModule from '../kitchenStoreModule'
import kitchenListAddNew from './KitchenListAddNew.vue'
import usekitchensList from './useKitchensList'
import { export_table_to_excel } from '@/toExcel'

export default {
  components: {
    kitchenListAddNew,
  },
  setup(props, { emit }) {
    const kitchen_APP_STORE_MODULE_NAME = 'app-kitchen'

    // Register module
    if (!store.hasModule(kitchen_APP_STORE_MODULE_NAME)) {
      store.registerModule(kitchen_APP_STORE_MODULE_NAME, kitchenStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(kitchen_APP_STORE_MODULE_NAME)) store.unregisterModule(kitchen_APP_STORE_MODULE_NAME)
    })
    const {
      kitchenListTable,
      tableColumns,
      searchQuery,
      roleFilter,
      planFilter,
      statusFilter,
      totalkitchenListTable,
      loading,
      options,
      kitchenTotalLocal,
      selectedRows,

      fetchkitchens,
      resolvekitchenRoleVariant,
      resolvekitchenRoleIcon,
      resolvekitchenStatusVariant,
      resolvekitchenTotalIcon,
    } = usekitchensList()

    const isAddNewkitchenSidebarActive = ref(false)

    const roleOptions = [
      { title: 'Admin', value: 'admin' },
      { title: 'Author', value: 'author' },
      { title: 'Editor', value: 'editor' },
      { title: 'Maintainer', value: 'maintainer' },
      { title: 'Subscriber', value: 'subscriber' },
    ]

    const planOptions = [
      { title: 'kitchen Name', value: 'basic' },
      { title: 'Mobile Number', value: 'company' },
      { title: 'Rate', value: 'enterprise' },
    ]

    // Delete a kitchen
    const handleDelete = kitchenId => {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      }).then(result => {
        if (result.isConfirmed) {
          store
            .dispatch('app-kitchen/deletekitchen', kitchenId)
            .then(() => {
              Swal.fire('Deleted!', 'entry has been deleted.', 'success')
              kitchenListTable.value = kitchenListTable.value.filter(item => item.id !== kitchenId)
            })
            .catch(response => {
              console.error(response)
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: response.message,
                footer: 'This kitchen might have items that needs to be deleted first',
              })
            })
        }
      })
    }

    const downLoadxlsx = () => {
      console.log('downLoadxlsx');
      export_table_to_excel('table', tableColumns, 'kitchensList')
    }


    return {
      dotenv,
      downLoadxlsx,
      kitchenListTable,
      tableColumns,
      searchQuery,
      roleFilter,
      planFilter,
      statusFilter,
      totalkitchenListTable,
      roleOptions,
      planOptions,
      loading,
      options,
      kitchenTotalLocal,
      isAddNewkitchenSidebarActive,
      selectedRows,
      resolvekitchenRoleIcon,
      avatarText,
      resolvekitchenRoleVariant,
      resolvekitchenStatusVariant,
      resolvekitchenTotalIcon,
      fetchkitchens,
      handleDelete,

      // icons
      icons: {
        mdiSquareEditOutline,
        mdiFileDocumentOutline,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiPlus,
        mdiExportVariant,
        mdiAccountOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
